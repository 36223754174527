import { BigNumber } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  colors,
  useTitle,
  EthereumContext,
  icons,
  useDebounce,
  FlexColumn,
  constants,
  log,
  TokenCards,
} from "../index";
import { ClaimContext } from "./Mint";

const InventoryPageSection = styled.div`
  position: relative;
  /* background-color: white; */
  display: flex;
  flex: 1;
  // padding: 45px;
  padding: 10px 0;
  width: 100%;
  transition: 0.4s ease all;
  justify-content: center;
  color: ${({ theme }: { theme: string }) =>
    theme === "dark" ? colors.text.section.dark : colors.text.section.light};
`;

const InventoryFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 1250px;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;

const InventoryTitle = styled.h1`
  font-family: "${() => constants.fonts.title}";
  letter-spacing: 2px;
  text-align: center;
  color: white;
`;

const ShadowText = styled.span`
  text-shadow: 3px 1px 3px #000000, 3px 1px 12px #000000;
`;

const ClaimButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  &:hover {
    transition: box-shadow 100ms ease, transform 100ms ease;
    box-shadow: 0px 3px 8px 0px hsl(93.931, 63.319%, 30%);
    transform: translateY(-4px);
  }
`;

const Button = styled(motion.button)`
  background-color: black;
`;

export interface ITrait {
  trait_type: string;
  value: string;
}

export interface IToken {
  uri: string;
  data: {
    attributes: ITrait[];
    image: string;
  };
}

export const Inventory: React.FunctionComponent = () => {
  useTitle({ page: `Claim` });
  const { account, contractState } = useContext(EthereumContext);
  const { setClaim, checked } = useContext(ClaimContext);
  const [tokenURIs, setTokenURIs] = useState<string[]>([]);

  useEffect(() => {
    async function go() {
      if (
        contractState &&
        contractState.tokensInWallet &&
        contractState.tokensInWallet.length > 0 &&
        contractState.baseURI &&
        account
      ) {
        const _tokenURIs: string[] = [];
        [...contractState.tokensInWallet].forEach(
          async (tokenId: BigNumber) => {
            const tokenURI = `${contractState.baseURI}${tokenId.toString()}`;
            if (_tokenURIs.indexOf(tokenURI) === -1) _tokenURIs.push(tokenURI);
          }
        );
        setTokenURIs(_tokenURIs);
      }
    }
    go();
  }, [contractState!.baseURI, contractState!.tokensInWallet, account]);

  //when the tokenUris are ready then execute
  const debouncedTokenURIs = useDebounce<string[]>(tokenURIs, 1000);

  const [tokenData, setTokenData] = useState<IToken[]>([]);
  const fetchData = () => {
    const currentLength = tokenData.length;
    const totalLength = debouncedTokenURIs.length;
    const nextTarget = currentLength + 20;
    const slice = debouncedTokenURIs.slice(
      currentLength,
      nextTarget > totalLength ? totalLength : nextTarget
    );
    function getSlice(uri: string): Promise<IToken> {
      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then((response: any) => {
            console.log(response.data);
            const { attributes, image } = response.data;
            const item = { uri, data: { attributes, image } };
            resolve(item);
          })
          .catch((err: any) => reject(`Error: ${err.message}`));
      });
    }
    const promises = [];
    for (let i = 0; i < slice.length; i++) {
      promises.push(getSlice(slice[i]));
    }
    Promise.all(promises)
      .then((newData: IToken[]) => {
        setTokenData([...tokenData, ...newData]);
      })
      .catch((error) => {
        log(error);
      });
  };

  //on load fetch initial batch
  useEffect(() => {
    if (debouncedTokenURIs && debouncedTokenURIs.length > 0) {
      fetchData();
    }
  }, [debouncedTokenURIs]);

  const [hasMore, setHasMore] = useState<boolean>(true);
  useEffect(() => {
    if (
      contractState &&
      contractState.tokensInWallet &&
      contractState.tokensInWallet.length &&
      tokenData.length > 0
    ) {
      setHasMore(contractState.tokensInWallet.length !== tokenData.length);
    } else if (
      contractState &&
      contractState.tokensInWallet &&
      contractState.tokensInWallet.length === 0
    ) {
      setHasMore(false);
    }
  }, [contractState!.tokensInWallet, tokenData]);

  const FaSpinner = icons.FaSpinner;
  const RightArrow = icons.FiChevronRight;

  return (
    <>
      <InventoryPageSection className="noselect">
        <FlexColumn>
          {contractState &&
            contractState.tokensInWallet &&
            contractState.tokensInWallet.length > 0 && (
              <InventoryFrame>
                <InfiniteScroll
                  style={{ overflow: "visible" }}
                  dataLength={contractState.tokensInWallet.length}
                  next={fetchData}
                  hasMore={hasMore}
                  hasChildren={true}
                  scrollThreshold={0.5}
                  loader={
                    <div className="d-flex justify-content-center">
                      <Button
                        onClick={fetchData}
                        className="btn btn-secondary mt-5"
                      >
                        <span>
                          <FaSpinner
                            className="icon-spin"
                            style={{ marginRight: 5 }}
                          />{" "}
                        </span>
                        Load more...
                      </Button>
                    </div>
                  }
                >
                  {tokenData.filter(
                    (i) => i.data.attributes[1].value == "Unclaimed"
                  ).length > 0 && (
                    <>
                      <InventoryTitle>
                        <ShadowText>
                          Claimable {constants.token.symbol}
                        </ShadowText>
                        &nbsp;
                        {checked!.length > 0 &&
                          contractState &&
                          contractState.tokensInWallet &&
                          account && (
                            <ClaimButton
                              className={"badge rounded-pill"}
                              onClick={() => setClaim!(true)}
                              style={{
                                backgroundColor: "#83d544",
                                color: "black",
                                padding: "12px 19.5px",
                              }}
                            >
                              Claim {checked!.length}
                              <RightArrow />
                            </ClaimButton>
                          )}
                      </InventoryTitle>
                      <div className="d-flex flex-wrap justify-content-center">
                        <TokenCards tokenData={tokenData} status="Unclaimed" />
                      </div>
                    </>
                  )}
                  {tokenData.filter(
                    (i) => i.data.attributes[1].value != "Unclaimed"
                  ).length > 0 && (
                    <>
                      <InventoryTitle>
                        <ShadowText>
                          Claimed {constants.token.symbol}
                        </ShadowText>
                      </InventoryTitle>
                      <div className="d-flex flex-wrap justify-content-center">
                        <TokenCards tokenData={tokenData} status="Claimed" />
                      </div>
                    </>
                  )}
                </InfiniteScroll>
              </InventoryFrame>
            )}
        </FlexColumn>
      </InventoryPageSection>
    </>
  );
};
