import React, { createContext, useState } from "react";
import styled from "styled-components";
import { colors, useTitle, ParticlesBackground, Inventory } from "../index";
import video from "../../images/dcave.mp4";
import { FormPage } from "./Form";

const MintPageSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* padding-bottom: 45px; */
  width: 100%;
  transition: 0.4s ease all;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: ${({ theme }: { theme: string }) =>
    theme === "dark" ? colors.text.section.dark : colors.text.section.light};
`;

const VideoPlayer = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  // z-index: -100;
`;

const Video = ({ video }: { video: string }) => {
  return (
    <VideoPlayer autoPlay loop muted>
      <source src={video} />
    </VideoPlayer>
  );
};

interface IClaimContextValues {
  setClaim: React.Dispatch<React.SetStateAction<boolean>>;
  checked: number[];
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ClaimContext = createContext<Partial<IClaimContextValues>>({});

export const Mint: React.FunctionComponent = () => {
  useTitle({ page: `Claim Site` });

  // const url = "http://137.184.208.249:42069/";
  const [claim, setClaim] = useState<boolean>(false);
  const [checked, setChecked] = useState<number[]>([]);

  return (
    <ClaimContext.Provider
      value={{
        setClaim,
        checked,
        setChecked,
      }}
    >
      {/* <ParticlesBackground /> */}
      <MintPageSection className="noselect">
        <Video video={video} />
        <ParticlesBackground />
        {!claim ? <Inventory /> : <FormPage />}
      </MintPageSection>
    </ClaimContext.Provider>
  );
};
