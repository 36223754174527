import { useContext, useState } from "react";
import styled from "styled-components";
import { ClaimContext } from "./Mint";
import AutoComplete from "react-google-autocomplete";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { processError } from "../contexts/ethereum";
import { ethers } from "ethers";
import { targetNetwork } from "../constants";
import { useTheme } from "../contexts";
import { useTitle } from "../hooks";

const FormFrame = styled.div`
  // position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  align-items: center;
  @media screen and (max-width: 400px) {
    width: 100%;
  }
  justify-content: center;
  z-index: 1;
`;

const FormControl = styled.div`
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  text-align: start;
  & input {
    font-family: sans-serif;
    background: #f1f5f8;
    border-color: transparent;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
    /* height: auto; */
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    /* height: 80px; */
  }
`;
const AddressControl = styled(FormControl)`
  display: block;
`;

const Form = styled.form`
  width: 50%;
  // display: flex;
  background-color: white;
  padding: 8px;
  margin: 0 auto;
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
  font-family: Monument;
  color: #222;
  @media screen and (max-width: 1000px) {
    width: 75%;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
    font-size: 13.5px;
  }
  & h5 {
    font-size: 18px;
  }
`;

const ClaimedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  padding: 20px;
  white-space: pre-line;
  text-align: center;
  border-radius: 10px;
  border: #83d544 solid 2px;
  margin: 0 10px;
  font-family: "Monument";
`;

const ButtonFrame = styled.div`
  margin-top: 0px;
  // margin-bottom: 20px;
  max-width: 500px;
  padding-top: 20px;
  @media screen and (max-width: 1050px) {
    // margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    // border: 0px;
    width: 80%;
    justify-content: center;
  }
`;
const Button = styled(motion.button)`
  transition: 0.1s ease-in-out all;
  padding: 10px 20px;
  padding-top: 13px;
  min-width: 200px;
  background: #83d544;
  color: black;
  font-size: 18px;
  border-radius: 5px;
  border: 0;
  font-family: "Monument";
  font-weight: bold;
  box-shadow: black 2px 2px 0px, black 2px 2px 0px, 2px 1px 3px rgba(0, 0, 0, 0);
  @media screen and (max-width: 400px) {
    min-width: 75%;
  }
  &:hover {
    background-color: #b3e18f;
    color: white;
    font-weight: bold;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

interface IPerson {
  name: string;
  email: string;
  token: string;
  account: string;
}

export const FormPage = (): JSX.Element => {
  useTitle({ page: `Claim Form` });
  const url = "https://dcave-claim-api.atlascorp.io/";

  const { checked } = useContext(ClaimContext);
  const [claim, setClaim] = useState(false);
  const { triggerSuccess } = useTheme()!;

  const [person, setPerson] = useState<IPerson>({
    name: "",
    email: "",
    token: "",
    account: "",
  });

  const [address, setAddress] = useState({
    address: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    formattedAddress: "",
  });

  const [formErr, setFormErr] = useState({
    name: false,
    email: false,
  });

  const [adrErr, setAdrErr] = useState({
    address: false,
    city: false,
    state: false,
    zip: false,
    country: false,
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setPerson({ ...person, [name]: value });
    setFormErr({ ...formErr, [name]: false });
  };

  const handleAddress = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddress({ ...address, [name]: value });
    setAdrErr({ ...adrErr, [name]: false });
  };

  const handleSubmitAddress = (place: any) => {
    const addressNameFormat: { [key: string]: string } = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    };

    const getAddressComp = (type: string) => {
      for (const component of place.address_components) {
        if (component.types[0] === type) {
          return component[addressNameFormat[type]];
        }
      }
      return "";
    };

    console.log(typeof getAddressComp("street_number"));
    const sumbitedAddress = {
      address:
        getAddressComp("street_number") && getAddressComp("route")
          ? getAddressComp("street_number") + " " + getAddressComp("route")
          : "",
      city: getAddressComp("locality"),
      state: getAddressComp("administrative_area_level_1"),
      zip: getAddressComp("postal_code"),
      country: getAddressComp("country"),
      formattedAddress: place.formatted_address,
    };
    setAddress((add) => {
      return {
        ...add,
        ...sumbitedAddress,
      };
    });
    setAdrErr({
      address: !sumbitedAddress.address,
      city: !sumbitedAddress.city,
      state: !sumbitedAddress.state,
      zip: !sumbitedAddress.zip,
      country: !sumbitedAddress.country,
    });
    console.log(place);
  };

  const submitForm = async () => {
    console.log(person);
    if (
      person!.name &&
      person!.email &&
      address.address &&
      address.city &&
      address.state &&
      address.zip &&
      address.country
    ) {
      const { ethereum } = window as any;
      const provider = new ethers.providers.Web3Provider(ethereum);
      const wallet = await provider.send("eth_requestAccounts", []);

      const data = await fetch(url + "get-nonce/" + wallet[0])
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((err) => processError(err));

      if (data == undefined) {
        console.log("bye");
        return;
      }

      const signer = provider.getSigner();

      const domain = {
        name: "DCave Claim",
        version: "1",
        chainId: targetNetwork.chainId,
      };

      const types = {
        nonce: [
          { name: "address", type: "string" },
          { name: "nonce", type: "string" },
        ],
      };

      const value = {
        address: data.address,
        nonce: data.nonce,
      };

      const message = signer._signTypedData(domain, types, value);

      const signature = await message
        .then((data: any) => {
          return data;
        })
        .catch((err: any) => processError(err));

      let authenticate = undefined;

      if (signature) {
        const payload = {
          signature: signature,
          address: wallet[0],
          mailing_address: { ...address, ...person },
          chainID: targetNetwork.chainId,
          tokens: checked,
        };
        console.log(payload);
        const response = await fetch(url + "authenticate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }).then((response) => {
          return response;
        });
        authenticate = await response
          .json()
          .then((data) => {
            return data;
          })
          .catch((err) => {
            processError(err);
          });
        if (response.status == 200) {
          triggerSuccess();
          toast.success(authenticate.msg, { autoClose: 10000 });
        } else {
          toast.error(authenticate.msg, { autoClose: 10000 });
        }
        setClaim(true);
      }
    } else {
      setFormErr({
        name: !person.name,
        email: !person.email,
      });
      setAdrErr({
        address: !address.address,
        city: !address.city,
        state: !address.state,
        zip: !address.zip,
        country: !address.country,
      });
    }
  };

  return (
    <FormFrame>
      {!claim ? (
        <Form onClick={(e) => e.stopPropagation()}>
          <h5>Claim Computron {checked!.toString().replaceAll(",", ", ")}!</h5>
          <FormControl>
            <label htmlFor="name">Name: </label>
            <input
              type="text"
              id="name"
              name="name"
              value={person!.name}
              onChange={handleChange}
              style={{ borderColor: formErr.name ? "red" : "" }}
            ></input>
          </FormControl>
          <FormControl>
            <label htmlFor="email">Email: </label>
            <input
              type="email"
              id="email"
              name="email"
              value={person!.email}
              onChange={handleChange}
              style={{ borderColor: formErr.email ? "red" : "" }}
            ></input>
          </FormControl>

          <h6 style={{ marginTop: 15 }}>Shipping Information</h6>

          <AddressControl>
            <AutoComplete
              // apiKey={"AIzaSyAWY39rRUOhkjWj9Te2bhd9V8LkBK8VCAM"}
              // onPlaceSelected={(place) => console.log(place)}
              // options={{ types: "address" }}
              // ref={InputRef}
              style={{ borderColor: adrErr.address ? "red" : "" }}
              name={"address"}
              value={address.address}
              onChange={handleAddress}
              options={{ types: "address" }}
              onPlaceSelected={handleSubmitAddress}
              placeholder="Address"
            ></AutoComplete>
          </AddressControl>
          <AddressControl>
            <input
              type="text"
              id="apt"
              name="apt"
              value={address.apt}
              onChange={handleAddress}
              placeholder="Apt, Suite, etc (optional)"
            ></input>
          </AddressControl>
          <AddressControl style={{ margin: 0 }}>
            <input
              type="text"
              id="city"
              name="city"
              value={address.city}
              onChange={handleAddress}
              placeholder="City"
              style={{ borderColor: adrErr.city ? "red" : "" }}
            ></input>
          </AddressControl>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridColumnGap: "8px",
              margin: "0.5rem 0",
            }}
          >
            <AddressControl style={{ margin: 0 }}>
              <input
                type="text"
                id="state"
                name="state"
                value={address.state}
                onChange={handleAddress}
                placeholder="State/Province"
                style={{ borderColor: adrErr.state ? "red" : "" }}
              ></input>
            </AddressControl>
            <AddressControl style={{ margin: 0 }}>
              <input
                type="text"
                id="zip"
                name="zip"
                value={address.zip}
                onChange={handleAddress}
                placeholder="Zip/Postal code"
                style={{ borderColor: adrErr.zip ? "red" : "" }}
              ></input>
            </AddressControl>
          </div>
          <AddressControl>
            <input
              type="text"
              id="country"
              name="country"
              value={address.country}
              onChange={handleAddress}
              placeholder="Country"
              style={{ borderColor: adrErr.country ? "red" : "" }}
            ></input>
          </AddressControl>

          <Button type="button" onClick={submitForm}>
            Submit
          </Button>
        </Form>
      ) : (
        <ClaimedContainer>
          You've claimed your selected watches!{`\n`} Check out our Discord for
          more details
          <ButtonFrame>
            <Button
              onClick={() => {
                window.open("https://discord.com/invite/Rd9BRsrGKg", "_blank");
                window.location.reload();
              }}
            >
              Discord
            </Button>
          </ButtonFrame>
        </ClaimedContainer>
      )}
    </FormFrame>
  );
};
