import { AiFillHome, AiFillInfoCircle } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { BsLink } from "react-icons/bs";
import { GiHamburgerMenu, GiLever } from "react-icons/gi";
import {
  FaRegLightbulb,
  FaLightbulb,
  FaDiscord,
  FaTwitterSquare,
  FaSpinner,
  FaInstagramSquare,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { FiSend, FiChevronRight } from "react-icons/fi";

export const icons = {
  AiFillHome,
  AiFillInfoCircle,
  BiError,
  FaRegLightbulb,
  FaLightbulb,
  FaSpinner,
  FaDiscord,
  FaTwitterSquare,
  FaEye,
  FaEyeSlash,
  GiHamburgerMenu,
  FiSend,
  FiChevronRight,
  GiLever,
  BsLink,
  FaInstagramSquare,
};
