import contractABI from "./abi.json";

export const contractInformation: { address: string; abi: any[] } = {
  // address: '0x2f9ec40caBa127E435f06BD371d2F08Ce9cEAE17', //testnet v1
  // address: '0xd765367E806C0f88E0350DF30a8F11a2bf35D571', //testnet v2
  // address: '0x39ae129fd91c255c075ab8a8a40d0b090ccafb19', //testnet v3
  address: "0xc538Dde457362DC8fAc4cf1F6398e7A6EfbBDa38", //mainnet
  // address: "0x4c46b0562CB71AF8ADcfb659D410283f632Fe9A3", //mainnet
  abi: contractABI,
};
