import styled from "styled-components";
import { useContext } from "react";
import { colors, EthereumContext, useTitle, Button } from "../index";
import video from "../../images/dcave.mp4";
// import bg from "../../images/background.png";

const ConnectPageSection = styled.div`
  position: relative;
  background-color: black;
  flex-direction: column;
  flex: 1;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  transition: 0.4s ease all;
  justify-content: center;
  display: flex;

  color: ${({ theme }: { theme: string }) =>
    theme === "dark" ? colors.text.section.dark : colors.text.section.light};
`;

const ConnectFrame = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  z-index: 2;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  @media screen and (max-width: 1050px) {
    width: 100%;
  }
`;

const ConnectButtonFrame = styled.div`
  margin-top: 0px;
  // margin-bottom: 20px;
  max-width: 500px;
  @media screen and (max-width: 1050px) {
    // margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    // border: 0px;
    width: 80%;
    justify-content: center;
  }
`;

const VideoPlayer = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  // z-index: -100;
`;

const Video = () => {
  return (
    <VideoPlayer autoPlay loop muted>
      <source src={video} />
    </VideoPlayer>
  );
};

export const Connect: React.FunctionComponent = () => {
  useTitle({ page: `Connect Wallet` });
  const { connect } = useContext(EthereumContext);

  return (
    <ConnectPageSection className="noselect">
      <Video />
      <ConnectFrame>
        {/* <LogoFrame>
          <LogoImg src={logo} alt={`${constants.projectName} Logo`} />
        </LogoFrame> */}

        <ConnectButtonFrame>
          <Button
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.1 }}
            onClick={connect}
          >
            Connect Wallet
          </Button>
        </ConnectButtonFrame>
      </ConnectFrame>
    </ConnectPageSection>
  );
};
