import { createGlobalStyle } from "styled-components";
import { useTheme } from "../index";
import font1_woff from "../../fonts/MuseoSansRounded-100.woff";
import font1_woff2 from "../../fonts/MuseoSansRounded-100.woff2";
import font2_woff from "../../fonts/DIN-Black.woff";
import font2_woff2 from "../../fonts/DIN-Black.woff2";
import monument_bold from "../../fonts/PPMonumentExtended-Bold.woff";
import monument_bold2 from "../../fonts/PPMonumentExtended-Bold.woff2";
import monument_regular from "../../fonts/PPMonumentExtended-Regular.woff";
import monument_regular2 from "../../fonts/PPMonumentExtended-Regular.woff2";
import { constants } from "../constants";

export const GlobalStyleElem: any = createGlobalStyle`

  @font-face{
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 500;
    font-display:swap;
    src: url(https://fonts.gstatic.com/s/worksans/v16/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU7GsJoA.woff) format('woff')
  }

  @font-face{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v16/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNis.woff) format('woff')
  }

  @font-face{
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v16/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmwJoA.woff) format('woff')
  
  }
  @font-face {
    font-family: "${constants.fonts.body}";
    src: url(${monument_bold2}) format('woff2'),
      url(${monument_bold});
  }

  @font-face {
    font-family: "Monument";
    src: url(${monument_regular2}) format('woff2'),
      url(${monument_regular});
  }
  
  @font-face {
    font-family: '${constants.fonts.text}';
    src:  url(${font1_woff2}) format('woff2'),
      url(${font1_woff}) format('woff');
  }
  @font-face {
    font-family: '${constants.fonts.title}';
    src:  url(${font2_woff2}) format('woff2'),
      url(${font2_woff}) format('woff');
  }
  body {
    font-family: '${constants.fonts.body}';
    margin: 0px;
    overflow-X: hidden;
    transition: all 4s ease 0;
    background-color: ${({ colorHex }: { colorHex: string }) =>
      colorHex ? colorHex : "#79b4e0"};
  }

  .claimed {
    background: #5e9b2f;
  }

  .unclaimed {
    background: #83d544;
    :hover {
      background-color: #9cdd69;
      cursor: pointer;
      transform: scale(1.02)
    }
  }

  .checked { 
    background-color: #4f8029;
    :hover {
      cursor: pointer;
      transform: scale(1.02)
    }
  }
  .text-center {
    text-align: center;
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  .navbar-link-active {
    border-bottom: 4px solid #ffe42e;
  }
  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
`;

export const GlobalStyle: React.FunctionComponent = () => {
  const { theme, bgColor } = useTheme()!;

  return <GlobalStyleElem colorHex={bgColor} theme={theme} />;
};
