import React from "react";
import { useCookies } from "react-cookie";
import { constants } from "../constants";

const cookieName = `${constants.cookiePrefix}-theme`;

export const lightTheme = "light";
export const darkTheme = "dark";

export type ThemeContextType = {
  theme: string;
  bgColor: string;
  gradient: string | null;
  success: boolean;
  triggerSuccess: () => void;
  toggleTheme: () => void;
};

export const ThemeContext = React.createContext<ThemeContextType | undefined>(
  undefined
);

type Props = {
  children: React.ReactNode;
};
export const ThemeProvider = ({ children }: Props): JSX.Element => {
  const [cookies, setCookie] = useCookies([cookieName]);
  const [theme, setTheme] = React.useState(cookies[cookieName] || "light");
  const [success, setSuccess] = React.useState<boolean>(false);
  const triggerSuccess = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 20000);
  };
  const toggleTheme = () => {
    const set = theme === "light" ? "dark" : "light";
    setTheme(set);
    setCookie(cookieName, set);
  };
  React.useEffect(() => {
    const currentTheme = cookies[cookieName] || "light";
    setTheme(currentTheme);
  }, [cookies]);
  const [bgColor, setBgColor] = React.useState<string>("#FFFFFF");
  const [gradient, setGradient] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (theme) {
      switch (theme) {
        case "light":
          setBgColor("#cccccc");
          setGradient(
            `background: linear-gradient(150deg, #ffe8fa, #ffe3f9, #ffd6f7, #ffc3f6, #ffabf6, #ff90f8, #ff74fe, #f859ff, #ef41ff, #e62eff, #e021ff, #dd1cff);`
          );
          break;
        default:
        case "dark":
          setBgColor("#333333");
          setGradient(
            `background: linear-gradient(150deg, #1f005c, #220060, #29006d, #37007f, #490095, #6100af, #7e00c8, #9d00e2, #bc00f8, #d00bff, #da18ff, #dd1cff);`
          );
          break;
      }
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{ theme, bgColor, success, triggerSuccess, gradient, toggleTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType | undefined =>
  React.useContext(ThemeContext);
