import styled from "styled-components";
import {
  constants,
  contractInformation,
  icons,
  targetNetwork,
} from "../constants";
import { ClaimContext, IToken } from "../pages";
import { motion } from "framer-motion";
import { useContext } from "react";
import openseaLogo from "../../images/opensea.svg";
import { LazyImageCardTop } from "./LazyImage";
interface ITokenCard {
  tokenData: IToken[];
  status: "Claimed" | "Unclaimed";
}

const MotionA = styled(motion.a)`
  background-color: black;
  font-family: ${() => constants.fonts.text};
`;

const TokenImg = styled(LazyImageCardTop)`
  width: 285px;
  height: 285px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled(motion.button)`
  background-color: black;
`;

export const TokenCards = ({ tokenData, status }: ITokenCard): JSX.Element => {
  const { checked, setChecked } = useContext(ClaimContext);

  const handleCheck = (event: any, tokenId: number) => {
    let updatedList = [...checked!];
    if (checked!.includes(tokenId)) {
      updatedList.splice(checked!.indexOf(tokenId), 1);
    } else {
      updatedList = [...checked!, tokenId];
    }
    setChecked!(updatedList);
    console.log(updatedList);
  };

  const FaTwitterSquare = icons.FaTwitterSquare;

  const onTweet = (tokenId: string, openseaUrl: string) =>
    window.open(
      `http://twitter.com/share?text=Check out my @${
        constants.socials.twitter
      } number ${tokenId}!! &url=${openseaUrl}&hashtags=${constants.shareTweet.hashtags.join(
        ","
      )}
		`,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600"
    );

  return (
    <>
      {tokenData
        .filter((i) => i.data.attributes[1].value == status)
        .map((i, index) => {
          const s = i.uri.split("/");
          const tokenId = s[s.length - 1];
          const claimed: boolean = i.data.attributes[1].value != "Unclaimed";
          const openseaUrl = `${
            targetNetwork.opensea
          }assets/${contractInformation.address.toLowerCase()}/${tokenId}`;
          return (
            <div
              className={`card ${
                claimed
                  ? "claimed"
                  : checked!.includes(Number(tokenId))
                  ? "checked"
                  : "unclaimed"
              }`}
              onClick={(e) => {
                if (!claimed) handleCheck(e, Number(tokenId));
              }}
              style={{
                width: "18rem",
                margin: 5,
                // borderColor: !claimed ? "red" : "",
                // filter: !claimed ? "grayscale(100%)" : "",
              }}
              key={`card-${index}`}
              id={tokenId}
            >
              <TokenImg
                src={i.data.image}
                alt={`Image for ${constants.projectName} Token #${tokenId}`}
              />

              <div className="card-body">
                <p className="card-text" style={{ fontFamily: "Work Sans2" }}>
                  {constants.projectName} Token #{tokenId}
                </p>
                <MotionA
                  href={openseaUrl}
                  target="_BLANK"
                  className="btn btn-secondary"
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.1 }}
                >
                  <img
                    src={openseaLogo}
                    style={{ height: "auto", width: "80px" }}
                  />
                </MotionA>
                &nbsp;
                <MotionA
                  href={i.uri}
                  target="_BLANK"
                  className="btn btn-secondary"
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.1 }}
                >
                  MetaData
                </MotionA>
                &nbsp;
                <Button
                  className="btn btn-secondary"
                  onClick={() => onTweet(tokenId, openseaUrl)}
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.1 }}
                >
                  <FaTwitterSquare />
                </Button>
              </div>
            </div>
          );
        })}
    </>
  );
};
