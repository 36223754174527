import { HiOutlineViewGridAdd } from "react-icons/hi";
import { MdLibraryBooks } from "react-icons/md";
import { NETWORKS } from "./networks";

//set the target network
export const targetNetwork = NETWORKS.rinkeby;

export const constants = {
  token: {
    symbol: "Computron",
    maxSupply: 8888,
  },
  logo: `D-Cave x Bulova`,
  footer: `Join`,
  projectName: `D-Cave x Bulova Whitelist`,
  projectHomepage: `/`,
  baseCurrency: "ETH",
  supportedChainIds: [targetNetwork.chainId],
  socials: {
    atlasDiscord: "84GEXy3UxM",
    discord: "Rd9BRsrGKg",
    twitter: "dcaveofficial",
    instagram: "dcaveofficial",
  },
  shareTweet: {
    text: "I just minted a @lazylambnft! Join the army now at",
    url: "https://dcave.com/",
    hashtags: ["DCave", "Bulova", "DCaveNFT", "NFTCollector", "NFT"],
  },
  fonts: {
    title: "DinBlack",
    body: "Work Sans2",
    text: "MuseoSansRounded-100",
  },
  cookiePrefix: "atlas",
  navMenu: [
    {
      path: "/",
      text: "Mint",
      icon: HiOutlineViewGridAdd,
      include: ["sidebar", "navbar"],
    },
    {
      path: "/provenance",
      text: "Provenance",
      icon: MdLibraryBooks,
      include: ["sidebar", "navbar"],
    },
  ],
  pollerInterval: 25000,
};
