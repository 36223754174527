import styled from "styled-components";
import { constants, colors, topLogoAnim } from "../index";
import bulova from "../../images/bulova.svg";
import dcave from "../../images/dcave.svg";

const Nav = styled.nav`
  position: relative;
  height: 100px;
  color: white;
  display: flex;
  justify-content: center;
  transition: 0.4s ease all;
  flex-wrap: wrap;
  z-index: 10;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: ${() => colors.background.nav};
`;

const Link = styled.a`
  position: relative;
  padding-bottom: 10px;
  width: 100%;
  justify-content: center;
  margin: auto;
  animation: 2s ${topLogoAnim};
  transition: 0.4s ease all;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  align-items: end;
  @media screen and (max-width: 1050px) {
    padding-top: 22px;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
`;

const Bulova = styled.img`
  transition: 0.4s ease all;
  height: 50%;
  @media screen and (max-width: 1050px) {
    padding-top: 0px;
    width: 50%;
    margin: 5px 0px;
  }
`;
const Spacer = styled.div`
  width: 2%;
  margin: auto;
`;
const Dcave = styled.img`
  transition: 0.4s ease all;
  height: 50%;
  @media screen and (max-width: 1050px) {
    padding-top: 0px;
    width: 50%;
    margin: 5px 0px;
  }
`;

export const Navbar = (): JSX.Element => {
  return (
    <Nav>
      <Background />
      <Link href={constants.projectHomepage} title="Go to the homepage">
        <Dcave src={dcave} />
        <Spacer />
        <Bulova src={bulova} alt={`${constants.projectName} Logo`} />
      </Link>
    </Nav>
  );
};
