import styled from "styled-components";

export const HolyGrail = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  flex-direction: column;
`;

export const HolyGrailBody = styled.div`
  display: flex;
  flex: 1;
  transition: all 2s ease 0;
  background: black;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
