import React, { useContext, useCallback, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  EthereumContext,
  Blockie,
  FlexRow,
  FlexColumn,
  targetNetwork,
} from "../index";
import Davatar from "@davatar/react";
import { ethers } from "ethers";

const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  color: black;
  transition: 2s ease-in-out all;
  background-color: #83d544;
  font-family: Work Sans;
  font-weight: bold;
  font-size: 15px;
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  @media screen and (max-width: 1000px) {
    width: 100%;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const AddressElem = styled.p`
  font-family: "Courier New", Courier, monospace;
  margin: 0;
  font-weight: 600;
  font-size: 0.8rem;
  @media screen and (max-width: 400px) {
    width: 100%;
    font-size: 0.75rem;
  }
`;

const DisconnectBtn = styled.button`
  margin: 0;
  padding: 0;
  background-color: black;
  color: #9fd452;
  border: 0;
  border-radius: 6px;
  padding: 2px 6px;
  transition: 0.3s ease-in-out all;
  box-sizing: border-box;
  box-shadow: black 2px 2px 0px, black 2px 2px 0px, 2px 1px 3px rgba(0, 0, 0, 0);
  font-family: Monument;
  font-size: 18px;
  @media screen and (max-width: 430px) {
    font-size: 13px;
  }
  &:hover {
    color: #b3e18f;
    font-weight: bold;
    transform: scale(1.1);
  }
`;

function addressConcat(addr: string): string {
  const l = addr.length,
    n = 12;
  return addr.substring(0, n) + "..." + addr.substring(l - n, l);
}

const Address = ({ address }: { address: string | null | undefined }) => {
  const { logout } = useContext(EthereumContext);
  const [name, setName] = React.useState<string | null>(null);

  const provider = new ethers.providers.JsonRpcProvider(targetNetwork.rpcUrl);

  const fetchENS = useCallback(async () => {
    if (address) {
      // const ens = await provider.lookupAddress(account);
      const ens = await provider.lookupAddress(address);
      setName(ens);
    }
  }, [address]);

  useEffect(() => {
    fetchENS();
  }, [fetchENS]);

  return address ? (
    <FlexRow
      style={{
        alignItems: "center",
      }}
    >
      <div
        style={{
          paddingRight: 10,
        }}
      >
        <Davatar
          address={address.toLowerCase()}
          size={36}
          provider={provider}
          generatedAvatarType="blockies"
          style={{ borderRadius: "6px" }}
          defaultComponent={<Blockie address={address} size={36} />}
        />
      </div>
      <FlexColumn style={{ marginRight: 10 }}>
        {name ? name : <AddressElem>{addressConcat(address)}</AddressElem>}
      </FlexColumn>
      <DisconnectBtn
        onClick={() => {
          toast.dark("Disconnected from MetaMask", { autoClose: 1500 });
          if (logout) {
            logout();
          }
        }}
      >
        Disconnect
      </DisconnectBtn>
    </FlexRow>
  ) : (
    <></>
  );
};

export const TopAlert = (): JSX.Element => {
  const { errorMsg, account } = useContext(EthereumContext);
  return account ? (
    <Alert className="noselect">
      <Address address={account} />
    </Alert>
  ) : errorMsg ? (
    <Alert>{errorMsg}</Alert>
  ) : (
    <></>
  );
};
