import Particles from "react-tsparticles";
import styled from "styled-components";
import { useTheme } from "..";

const StyledParticles = styled(Particles)`
  position: absolute;
  //   background: #00356b;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
  @media (max-width: 700px) {
    height: 100%;
  }
`;

const Confetti = () => {
  return (
    <StyledParticles
      options={{
        autoPlay: true,
        // background: {
        //   image: `url(${bg})`,
        //   position: "50% 50%",
        //   size: "cover",
        //   opacity: 0,
        // },
        fullScreen: {
          enable: false,
          zIndex: 0,
        },
        detectRetina: true,
        duration: 0,
        fpsLimit: 120,
        interactivity: {
          detectsOn: "window",
          events: {
            resize: true,
          },
          modes: {
            attract: {
              distance: 200,
              duration: 0.4,
              factor: 1,
              maxSpeed: 50,
              speed: 1,
            },
            bubble: {
              distance: 200,
              duration: 0.4,
              mix: false,
            },
            connect: {
              distance: 80,
              links: {
                opacity: 0.5,
              },
              radius: 60,
            },
            grab: {
              distance: 100,
              links: {
                blink: false,
                consent: false,
                opacity: 1,
              },
            },
            light: {
              area: {
                gradient: {
                  start: {
                    value: "#ffffff",
                  },
                  stop: {
                    value: "#000000",
                  },
                },
                radius: 1000,
              },
              shadow: {
                color: {
                  value: "#000000",
                },
                length: 2000,
              },
            },
            push: {
              default: true,
              groups: [],
              quantity: 4,
            },
            remove: {
              quantity: 2,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
              factor: 100,
              speed: 1,
              maxSpeed: 50,
            },
            slow: {
              factor: 3,
              radius: 200,
            },
            trail: {
              delay: 1,
              pauseOnStop: false,
              quantity: 1,
            },
          },
        },
        motion: {
          disable: false,
          reduce: {
            factor: 4,
            value: true,
          },
        },
        particles: {
          bounce: {
            horizontal: {
              random: {
                enable: false,
                minimumValue: 0.1,
              },
              value: 1,
            },
            vertical: {
              random: {
                enable: false,
                minimumValue: 0.1,
              },
              value: 1,
            },
          },
          collisions: {
            bounce: {
              horizontal: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
              vertical: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
            },
            enable: false,
            mode: "bounce",
            overlap: {
              enable: true,
              retries: 0,
            },
          },
          color: {
            value: "#00ff00",
            animation: {
              // h: {
              //   count: 0,
              //   enable: true,
              //   offset: 0,
              //   speed: 50,
              //   sync: false,
              // },
              s: {
                count: 0,
                enable: false,
                offset: 0,
                speed: 1,
                sync: true,
              },
              l: {
                count: 0,
                enable: false,
                offset: 0,
                speed: 1,
                sync: true,
              },
            },
          },
          destroy: {
            split: {
              count: 1,
              factor: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 3,
              },
              rate: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: {
                  min: 4,
                  max: 9,
                },
              },
              sizeOffset: true,
            },
          },
          life: {
            count: 1,
            delay: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              sync: false,
            },
            duration: {
              random: {
                enable: false,
                minimumValue: 0.0001,
              },
              value: 5,
              sync: true,
            },
          },
          move: {
            angle: {
              offset: 0,
              value: 90,
            },
            attract: {
              distance: 200,
              enable: false,
              rotate: {
                x: 3000,
                y: 3000,
              },
            },
            decay: 0.1,
            distance: {},
            direction: "none",
            drift: 0,
            enable: true,
            gravity: {
              acceleration: 10,
              enable: true,
              inverse: false,
              maxSpeed: 50,
            },
            path: {
              clamp: true,
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
              },
              enable: false,
              options: {},
            },
            outModes: {
              default: "destroy",
              bottom: "destroy",
              left: "destroy",
              right: "destroy",
              top: "none",
            },
            random: false,
            size: false,
            speed: {
              min: 10,
              max: 20,
            },
            spin: {
              acceleration: 0,
              enable: false,
            },
            straight: false,
            trail: {
              enable: false,
              length: 10,
              fillColor: {
                value: "#000000",
              },
            },
            vibrate: false,
            warp: false,
          },
          number: {
            density: {
              enable: false,
              area: 800,
              factor: 1000,
            },
            limit: 0,
            value: 0,
          },
        },
        emitters: {
          autoPlay: true,
          fill: true,
          life: {
            wait: false,
            count: 0,
            delay: 0.4,
            duration: 0.1,
          },
          rate: {
            quantity: 150,
            delay: 0.1,
          },
          shape: "square",
          startCount: 0,
          size: {
            mode: "percent",
            height: 0,
            width: 0,
          },
          position: {},
        },
      }}
    />
  );
};

export const ParticlesBackground = (): JSX.Element => {
  const { success } = useTheme()!;
  //   const success = true;
  return success ? <Confetti /> : <></>;
};
