import React, { useState, useContext } from "react";
import {
  TopAlert,
  HolyGrail,
  HolyGrailBody,
  Navbar,
  Footer,
  AnimatedRoutes,
  RouteTransition,
  NoMatch404,
  EthereumContext,
  Connect,
  Mint,
} from "./common";

const App: React.FunctionComponent = () => {
  const { isConnectedToMetamask } = useContext(EthereumContext);
  return (
    <React.Fragment>
      <HolyGrail>
        <Navbar />
        <TopAlert />
        <HolyGrailBody>
          <AnimatedRoutes exitBeforeEnter initial={false}>
            <RouteTransition exact path="/" slide={35} slideUp={0}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!isConnectedToMetamask ? <Connect /> : <Mint />}
              </div>
            </RouteTransition>
            <RouteTransition exact path={undefined} slide={35} slideUp={0}>
              <NoMatch404 />
            </RouteTransition>
          </AnimatedRoutes>
        </HolyGrailBody>
        <Footer />
      </HolyGrail>
    </React.Fragment>
  );
};

export default App;
